.ant-layout-sider {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 70px;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  z-index: 9;
  box-shadow: @box-shadow;

  .ant-menu {
    background-color: transparent;
    .user-menus {
      .user-default {
        display: inline-flex;
        img {
          width:auto;
        }
      }
      .user-selected {
        display: none;
      }
    }

    .ant-menu-item-selected {
      background-color: #A1A1A11A;

      a {
        font-size: @font-14 + 1;
        font-weight: @semibold;
        color: @danger-text;
      }
      .user-menus {
        .user-selected {
          display: inline-flex;
          img {
            width:auto;
          }
        }
        .user-default {
          display: none;
        }
      }
    }
    .ant-menu-sub {
      .ant-menu-title-content {
        a {
          font-weight: 400;
        }
      }
    }

    .ant-image {
      margin-right: 20px;
    }
    a {
      display: block;
      color: @p-text-color;
    }
    .ant-menu-item {
      color: #3A3A3C;
      font-weight: 600 !important;
    }
  }

  .anticon {
    font-size: 16px;
  }

  ul {
    height: 100%;

    .ant-menu-item {
      padding: 0 24px !important;
      &::after {
        left: 0;
        right: auto;
        border: 0;
        background-image: @theme-gradient;
        width: 5px;
        margin-left: 1px;
      }

      a {
        font-weight: @semibold;
        &:hover {
          color: @danger-text;
        }
      }
    }
    .selectedRoleItem {
      .ant-menu-item {
        padding: 0 24px !important;
        background-color: #A1A1A11A;
         .user-default {
          display: none;
        }
        .user-selected {
          display: inline-flex;
          img {
            width:auto;
          }
        }
        &::after {
          left: 0;
          right: auto;
          border: 0;
          background-image: @theme-gradient;
          width: 5px;
          margin-left: 1px;
          transform: scaleY(1);
          opacity: 1;
          transition: transform .15s cubic-bezier(.645,.045,.355,1),opacity .15s cubic-bezier(.645,.045,.355,1);
        }
  
        a {
          font-weight: @semibold;
          &:hover {
            color: @danger-text;
          }
        }
      }
    }
    .selectedUserItem {
      .selectedStudioItem {
        .ant-menu-title-content {
          a {
            color:#c53c46;
          }
        }
      }
      .selectedAdminItem {
        .ant-menu-title-content {
          a {
            color:#c53c46;
          }
        }
      }
      .ant-menu-submenu-title {
        padding: 0 24px !important;
        background-color: #A1A1A11A;
         .user-default {
          display: none;
        }
        .user-selected {
          display: inline-flex;
          img {
            width:auto;
          }
        }
        .ant-menu-title-content {
          color:#c53c46;
        }
        .ant-menu-submenu-arrow {
          color: #ac2e4c;
        }
        
        &::after {
          content: "";
    left: 0;
    right: auto;
    border: 0;
    background-image: linear-gradient(
90deg,#720f57,#911257,#ad1c54,#c62e4d,#db4443);
    width: 5px;
    margin-left: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
        }
  
        a {
          font-weight: @semibold;
          &:hover {
            color: @danger-text;
          }
        }
      }
    }
    .ant-menu-submenu-title {
      font-weight: @semibold;
      &:hover {
        color: @danger-text !important;
        .ant-menu-submenu-arrow {
          color: @danger-text;
        }
      }
    }

    .ant-menu-title-content {
      width: 100%;
      transition: all 0.5s ease;
      opacity: 1;
      visibility: visible;
      max-width: 100%;
      margin-left: 0;
    }
    .disabled {
      .ant-menu-title-content {
        color: #9c9c9d;
        a,span {
          color: #9c9c9d;
        }
      }
      
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .ant-typography {
    padding: 15px 24px 10px;
    display: block;
  }

  &.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;

    .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      justify-content: center;
      padding: 0 16px !important;
      text-align: center;
      font-weight: @semibold;
    }

    .ant-menu-title-content,
    .-title-content {
      margin-left: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      max-width: 0;
      // display: none;
      display: inline-flex;
      // @include lg{
      //     max-width: 100%;
      //     opacity: 1;
      //     visibility: visible;
      //     margin-left: 10px;
      // }
    }

    .ant-typography {
      display: none;
    }
  }

  // @include lg{
  //     display: none;
  // }
  .ant-menu-submenu {
    .ant-menu-item {
      padding: 0 10px 0 70px !important;
      &:active {
        background-color: transparent !important;
      }
  
    }
  }
  &.ant-layout-sider-collapsed {
    ul {
      .selectedUserItem {
        .ant-menu-submenu-title {
          padding:0px 17px !important;
        }
      }
      .selectedRoleItem {
        .ant-menu-item {
          padding:0px 17px !important;
        }
      }
    }
  }
}

.user-menus {
  .user-default {
    display: inline-flex;
    img {
      width:auto;
    }
  }
  .user-selected {
    display: none;
  }
}

.ant-menu-submenu {
  &:hover {
    .ant-menu-submenu-arrow {
      color: @danger-text !important;
    }
  }
}

.ant-menu-submenu-title {
  font-weight: 500;
}

.ant-menu-root {
  .ant-menu-item {
    font-weight: 500 !important;
  }
}

.sidenav-container {
  .ant-layout-sider {
    @media @lg {
      display: none;
    }
  }
  
}

.ant-menu-submenu-popup .ant-menu-item {
  padding-left:0px !important;
  text-align:center;
}

.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-selected{
  background-color: #f5f5f5;
}

.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-selected a{
  color:#000;
}

.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-active{
  background-color: #f5f5f5;
}

.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-active a{
  color:#000;
}
.ant-layout-sider-collapsed .ant-menu-submenu .ant-menu-item-icon {
  margin-top:8px !important;
}

.ant-layout-sider-collapsed .ant-menu .ant-menu-item-icon {
  margin-top:8px !important;
}

.ant-menu-submenu-selected {
  color: @danger-text !important;
  .user-menus {
    .user-selected {
      display: inline-flex !important;
      img {
        width:auto;
      }
    }
    .user-default {
      display: none !important;
    }
  }
  .ant-menu-submenu-arrow {
    color: #ac2e4c;
  }
  .ant-menu-submenu-title {
    background-color: #A1A1A11A;
    &::before {
      content: '';
      left: 0;
      right: auto;
      border: 0;
      background-image: @theme-gradient;
      width: 5px;
      margin-left: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    &::after {
      display: none;
    }
  }
  
}