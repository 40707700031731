body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}

a,
button,
.btn {
  // @include transition(all .5s ease);
  cursor: pointer;
}

a {
  color: @link-text-color;
  font-size: @font-14;
}

.btn:focus {
  box-shadow: none;
  outline: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.hidden {
  display: none;
}

h1, h2, h3, h4, h5, h6, p,span{
  margin-bottom: 0;
  word-break: break-word;
}

.font-xl {
  font-size: 48px;
}

.font-lg {
  font-size: 32px;;
}

.font-md-sm{
  font-size: 24px;
} 

.font-md-xs {
  font-size: 22px;
}

.font-md {
  font-size: 18px;
}

.font-regular {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-sm{
  font-size: 12px;
}

.text-success {
  color: @success !important;
}

.text-danger {
  color: #D24344 !important;
}

input {
  border-radius: 4px !important;
}

.ant-select-selector,
.ant-input-affix-wrapper {
  border-radius: 4px !important;
}

.link__goback {
  background-color: #2a59d1 !important;
  border-color: #2a59d1;
  border-radius: 0.625rem;
  color: @white !important;
  height: 40px;
  padding: 10px 45px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  > span {
    margin-right: 5px;
  }
  &:hover {
    background-color: #699dff !important;
    border-color: #699dff;
  }
}

.filter__select__wrap {
  > div {
    margin-right: 15px;
    h4 {
      margin-bottom: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.text__capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.ant-form-item-required {
  font-size: 14px !important;
  &::before {
    display: none !important;
  }
}

.w-20 {
  max-width: 20%;
}

.w-30 {
  max-width: 30%;
}

.w-40 {
  max-width: 40%;
}

.w-60 {
  max-width: 60%;
}

.w-70 {
  max-width: 70%;
  @media @lg {
    width: 100%;
  }
}

.w-80 {
  max-width: 80%;
  @media @lg {
    width: 100%;
  }
}

.w-90 {
  max-width: 90%;
  @media @lg {
    width: 100%;
  }
}

.toast-message {
  display: flex;
  align-items: center;
  border-radius: @radius;
  padding: 2px 8px;
  font-size: @font-regular;
  &.success {
    background-color: @success; 
  }
  &.danger {
    background-color: @danger; 
  }
  color: @white;
  .anticon {
    background-color: white;
    border-radius: 50%;
    width: 25px;
    padding: 5px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    svg {
      color: @danger;
    }
  }
}

.white-space-normal {
  white-space: normal;
}

.mobile-block {
  display: none;
  justify-content: center;
  background-color: #000;
  .mobile_device  {
    height: 100vh;
    background-color: #000;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .mobile_device_inner {
    p {
      font-size: 18px;
      color: #fff;
      margin: 0 0 10px;
      &.text-block {
        width: 500px!important;
      }
    }
  }
  .mobile_device_img {
    width: 200px;
    display: block;
    margin: 0 auto;
    img {
      width: 200px;
      margin-bottom: 30px;
    }
  }
  
}

@media screen and (max-width: 1080px) {
  .mobile-block {
    display: flex;
  }
  .dektop_device {
    display: none;
  }
}